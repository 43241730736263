<template>
    <div id="note-card">
      <b-card no-body class="card-custom card-custom-footer-pagination mb-3">
        <template #header>
          <b-link v-if="user.hasPermission('rtc:call.note.create')" v-b-tooltip.hover title="New Note" class="float-right" v-b-modal.modal-note>
            <BIconPlus font-scale="1.2"/>
          </b-link>
          <h6 class="mb-0">Notes</h6>
        </template>
        <NoteModal v-if="user.hasPermission('rtc:call.note.create')" ref="modalNoteEl" @deleted="refreshNotesList()" @saved="refreshNotesList()" :call="call"/>
        <NotesList v-if="!notes.isLoading" @note-selected="openNote" :notes="notes" style="max-height: 300px; overflow: auto;"/>
        <b-spinner v-else label="Loading..." small style="margin: 20px;"></b-spinner>
        <template #footer v-if="!notes.isLoading && notes.pagination.totalPages > 1">
          <b-pagination @change="getNotes" v-model="notes.pagination.currentPageNo" :total-rows="notes.total" :per-page="notes.pagination.perPage" size="sm" align="fill"></b-pagination>
        </template>
      </b-card>
    </div>
</template>

<script>
import {BIconPlus} from 'bootstrap-vue';
import NoteModal from './Note.Modal';
import NotesList from './Notes.List';
export default {
    props: ['call','user'],
    components: {
      NoteModal,NotesList,
      BIconPlus
    },
    data(){
        return {
          notes: {
            isLoading: true,
            records: [],
            total: 0,
            pagination: {
              currentPageNo: 1
            }
          }
        };
    },
    methods: {
      refreshNotesList(){
        this.getNotes(1);
      },

      openNote(data){
        this.$refs['modalNoteEl'].openModal(data);
      },

      getNotes(pageNo=1){
        this.notes.isLoading = true;
        this.$api.get(`call/${this.call.id}/notes`,{
          params: {
            perPage: 5,
            page: pageNo,
          }
        }).then(res => {
          let {records, pagination, total} = res.data;
          this.notes.isLoading = false;
          this.notes.records = records;
          this.notes.pagination = pagination;
          this.notes.total = total;
        }).catch((e) => {console.log(e,'cannot get notes')});
      }
    },

    mounted(){
      this.getNotes(1);
    }
};
</script>
