<template>
  <div v-if="!isLoading">
    <div v-if="showChat">
      <div class="chat">
        <div ref="messagesContainer" class="messages-container">
          <ul class="messages-grup">
            <li class="messages-group-item" v-for="(group,key) in parsedMessages" :key="key">
              <div class="messages-group-item-title">{{ group.title }}</div>

              <ul class="messages-list">
                <li :class="item.className" v-for="(item,key) in group.messages" :key="key">
                  <div class="message-container">
                    <div class="message-participant">{{ item.participant }}</div>
                    <div class="message-content">{{ item.message }}</div>
                  </div>
                  <div class="message-time">{{ item.time }}</div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="write-message">
          <div v-if="group.status === 'active'">
            <b-button variant="primary" class="message-send-btn" :disabled="!message" @click="sendMessage"><BIconCursor/></b-button>
            <b-form-textarea class="message-input" v-model="message" placeholder="write a message..." rows="1" max-rows="1" no-resize
              @keydown.enter.exact.prevent
              @keyup.enter.exact="sendMessage"
            ></b-form-textarea>
          </div>
          <div v-else class="message-input-closed">chat is closed</div>
        </div>
      </div>
    </div>
    <div v-else class="chat-disabled">chat is disabled here</div>
  </div>
  <div v-else  style="padding:20px;"><b-spinner label="Loading..." small></b-spinner></div>
</template>

<script>
import './style.css';
import {BIconCursor} from 'bootstrap-vue';
import moment from 'moment';
export default {
  props: ['groupConfig','user'],
  components: {
    BIconCursor
  },
  data(){
    return {
      isLoading: true,
      showChat: false,
      group: null,
      message: null,
      rawMessages: {
        pagination: {
          totalPages: 0,
          perPage: 25,
          currentPageNo: 1
        },
        total: 0,
        records: []
      },
      parsedMessages: null
    };
  },

  methods: {
    parseParticipant(item){
      let myself = false;
      let participant = null;

      if(item.userId !== undefined){
        if(item.userId === this.user.get('id')){
          myself = true;
          participant = this.user.fullName();
        }
        else{
          myself = false;
          let user = this.users.find(o => o.id === item.userId);
          if(user){
            participant = user.fullName || user.id;
          }
        }
      }

      if(item.anonymParticipantId !== undefined){
        myself = false;
        participant = item.anonymParticipantId;
      }

      return {
        myself: myself,
        participant: participant,
        className: myself ? "msg-container-myself" : "msg-container-participant"
      }
    },
    parseMessage(item){
      return Object.assign({
        id: item.id,
        message: item.message,
        createdAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        time: moment(item.createdAt).format('HH:mm:ss'),
      },this.parseParticipant(item));
    },
    parseMessages(messages){
      let parsedMessages = {};

      messages.forEach(item => {
        let datetime = moment(item.createdAt).format('YYYY-MM-DD');
        if(parsedMessages[datetime] === undefined){
          parsedMessages[datetime] = {
            title: moment(item.createdAt).format('DD/MM/YYYY'),
            messages: []
          };
        }

        parsedMessages[datetime].messages.push(this.parseMessage(item));
      });

      return parsedMessages;
    },

    getMessages(){
      this.isLoading = true;
      return this.$api.get(`chat/${this.groupConfig.groupId}/messages`).then(({data}) => {
        this.isLoading = false;
        this.rawMessages = data;
        this.parsedMessages = this.parseMessages(data.records);
        this.showChat = true;
      })
      .catch((e) => {
        this.isLoading = false;
        this.showChat = false;
        console.log("cannot fetch messages",e);
      });
    },
    getGroup(){
      this.isLoading = true;
      return this.$api.get(`chat/${this.groupConfig.groupId}`).then(({data}) => {
        this.isLoading = false;
        this.showChat = true;
        this.group = data;
      })
      .catch((e) => {
        this.isLoading = false;
        this.showChat = false;
        console.log("cannot fetch group",e);
      });
    },

    addMessage(message){
      let datetime = moment().format('YYYY-MM-DD HH:mm:ss');
      let today = moment(datetime).format('YYYY-MM-DD');
      if(!this.parsedMessages[today]){
        this.parsedMessages[today] = {
          title: moment(today).format('DD/MM/YYYY'),
          messages: []
        }
      }

      this.parsedMessages[today].messages.push(this.parseMessage(message));
      this.parsedMessages = Object.assign({}, this.parsedMessages);
    },

    sendMessage(e){
      e.preventDefault();

      let datetime = moment().format('YYYY-MM-DD HH:mm:ss');
      let today = moment(datetime).format('YYYY-MM-DD');
      if(!this.parsedMessages[today]){
        this.parsedMessages[today] = {
          title: moment(today).format('DD/MM/YYYY'),
          messages: []
        }
      }

      this.parsedMessages[today].messages.push(this.parseMessage({
        id: Math.random(),
        message: this.message,
        createdAt: datetime,
        time: moment(datetime).format('HH:mm:ss'),
        userId: this.user.get('id')
      }));
      this.parsedMessages = Object.assign({}, this.parsedMessages);

      this.$callstr.signal.emit('send-chat-message',Object.assign({
        message: this.message
      },this.groupConfig));

      setTimeout(() => {
        this.scrollToBottom();
      },300);

      this.message = null;
    },

    scrollToBottom(){
      if(!this.showChat){
        return false;
      }

      let div = this.$refs['messagesContainer'];
      div.scrollTop = div.scrollHeight;
    }
  },

  mounted(){
    this.getGroup().then(() => {
      this.getMessages().then(() => {
        this.scrollToBottom();

        this.$callstr.signal.off('received-chat-message');
        this.$callstr.signal.on('received-chat-message',(data) => {
          this.addMessage(data.message);
          setTimeout(() => {
            this.scrollToBottom();
          },300);
        });
      })
    });
  },

  computed: {
    users(){
      return this.$store.getters['Callcenter/getUsers'];
    }
  }
};
</script>
