<template>
    <div id="notes-list">
      <b-list-group v-if="notes.records.length > 0" flush>
          <b-list-group-item href="#" @click="setSelected(item, $event)" v-for="(item, k) in notes.records" :key="k">
            <div class="small mb-2">from: {{dateFormatter(item.createdAt,'DD/MM HH:mm')}}</div>
            <div v-html="item.content"></div>
          </b-list-group-item>
      </b-list-group>
      <div v-else style="padding: 20px;">you don`t have any notes</div>
    </div>
</template>

<script>
import {formatDate} from '@/common/utils/common';
export default {
    props: ['notes'],
    methods: {
      dateFormatter(value, format){
        return formatDate(value, format);
      },
      setSelected(item,e){
        e.preventDefault();
        this.$emit('note-selected', item);
      }
    },
};
</script>
