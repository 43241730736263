<template>
    <div id="call-details" class="page">
        <b-card class="card-custom mb-3">
          <b-card-text v-if="call">
            <b-row>
              <b-col class="text-left">
                <div class="float-left">
                  <BIconTelephoneForward v-if="call.logType === 'outgoing'" variant="primary" class="mr-3" font-scale="2.8" />
                  <BIconTelephoneInbound v-if="call.logType === 'incoming'" variant="success" class="mr-3" font-scale="2.8"/>
                  <!--<BIconTelephoneX v-if="call.type === 'missed'" variant="danger" class="mr-3" font-scale="2.8"/>-->
                </div>
                <div v-if="call.channel">channel: <strong>{{ call.channel.name }}</strong></div>
                <div v-if="member">agent: <strong>{{ member.fullName }}</strong></div>

                <div class="small">
                  <span v-if="call.type === 'missed'">missed</span>
                  <span v-if="call.type === 'outgoing'">made</span>
                  <span v-if="call.type === 'incoming'">received</span>
                  on {{ dateFormatter(call.createdAt,'DD/MM/YYYY HH:mm:ss') }}
                </div>
              </b-col>
              <b-col class="text-right">
                <div>from: <strong>{{call.visitorId.length > 20 ? call.visitorId.slice(0,20)+'...' : call.visitorId}}</strong></div>
                <div class="small">duration: {{ callDurationFormatter(call.duration) }}</div>
              </b-col>
            </b-row>
          </b-card-text>
          <b-spinner v-else label="Loading..." small></b-spinner>
        </b-card>

        <b-row>
          <b-col col lg="6" md="12" sm="12">
            <b-card class="card-custom mb-3" no-body>
              <template #header>
                <h6 class="mb-0">Messages</h6>
              </template>
              <Chat v-if="call && user.hasPermission('chat:messageGroup.messages')" :groupConfig="chatGroupConfig" :user="user" style="position: relative; height: 600px;" />
            </b-card>

          </b-col>
          <b-col col lg="6" md="12" sm="12">
            <NoteCard v-if="call && user.hasPermission('rtc:call.notes.search')" :call="call" :user="user"/>
          </b-col>
        </b-row>



        <!--
        <b-row>
          <b-col col lg="8" md="12" sm="12">
            <ChatCard v-if="user.hasPermission('chat:messages.search')"/>
          </b-col>
          <b-col col lg="4" md="12" sm="12">
            <RatingCard/>
            <NoteCard v-if="call && user.hasPermission('rtc:call.notes.search')" :call="call" :user="user"/>
          </b-col>
        </b-row>
      -->
    </div>
</template>

<script>
import NoteCard from './../../components/Notes/Note.Card';
import Chat from '@/common/components/Chat/Chat';
//import RatingCard from './../../components/CallRating/Rating.Card';
import {formatDate} from '@/common/utils/common';
import {
  BIconTelephoneForward,BIconTelephoneInbound,
  //BIconTelephoneX
} from 'bootstrap-vue';

export default {
    props: ['user'],
    components: {
      NoteCard,
      Chat,
      //RatingCard,
      BIconTelephoneForward,BIconTelephoneInbound,
      //BIconTelephoneX
    },
    data(){
        return {
          call: null,
          callee: null,
          chatGroupConfig: null
        };
    },

    methods:{
      dateFormatter(value, format){
        return formatDate(value, format);
      },
      callDurationFormatter(value){
        return value === null ? '-' : new Date(value * 1000).toISOString().substr(11, 8);
      },
      notifyParent(){
        let data = {active: "calls"};
        this.$emit('set-sidebar',data);
        this.$emit('set-navbar',data);
      },

      parseCall(data){
        let channels = this.$store.getters['Callcenter/getChannels'];
        data.channel = channels.find(o => o.id === data.channelId);
        return data;
      },

      getCallDetails(){
        this.$api.get(`call/${this.$route.params.callId}`).then(res => {
          this.call = this.parseCall(res.data);

          this.chatGroupConfig = {
            groupId: 'call-'+this.call.id,
            type: "call",
            options: {
              callId: this.call.id
            }
          }
        }).catch((e) => {console.log('could not get call',e)})
      }
    },

    computed: {
      member(){
        return this.$store.getters['Callcenter/getUsers'].find(o => o.id === this.call.calleeId);
      }
    },

    mounted(){
        this.notifyParent();
        this.getCallDetails();
    },
};
</script>
